import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import apiService from '../../services/apiservice';
import { toast } from "react-toastify";
import { MultiSelect } from 'primereact/multiselect';
import moment from 'moment';
import { FaEye } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import { FaUpload } from 'react-icons/fa';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const AddService = forwardRef((props, ref) => {
  const userObj = JSON.parse(localStorage.getItem('loginObj'))
  const roleCode = localStorage.getItem('userRole')
  console.log(props)
  const [loading, setloading] = useState(false);
  const [rejectBill, setrejectBill] = useState(false);
  const [enqObj, setenqObj] = useState()
  const [serviceObj, setserviceObj] = useState()
  const [serviceList, setserviceList] = useState([])
  const [procedureList, setprocedureList] = useState()
  const [docList, setdocList] = useState()
  const [billBlob, setBillblob] = useState()
  const [billBase64, setbillBase64] = useState()
  const [reportBlob, setReportBlob] = useState([])
  const [reportPayload, setReportpayload] = useState([])
  const [billType, setbillType] = useState()
  const [viewReport, setviewReport] = useState([])


  const serviceTypeDs = [
    { id: 8, value: 'IPD' },
    { id: 9, value: 'OPD' },
    { id: 10, value: 'Investigation' },
    { id: 11, value: 'Day Care ' },
    // { id: 7, value: 'Goverment Insurance' }
  ]

  const referalList = [
    { value: 0, name: 'Referral' },
    { value: 1, name: 'Non-Referral' },
  ]

  const subServiceList = [
    { value: 1, name: 'Emergency' },
    { value: 2, name: 'Non-Emergency' },
    { value: 3, name: 'Others' },

  ]
  const HospitalizatioList = [
    { value: 1, name: 'General Surgery' },
    { value: 2, name: 'Oncology' },
    { value: 3, name: 'Neurology' },
    { value: 4, name: 'Orthopedic' },
    { value: 5, name: 'Urology' },
    { value: 6, name: 'Cardiology' },
    { value: 7, name: 'ENT' },
    { value: 8, name: 'Gynecology' },
    // { value: 9, name: 'Gastrologic' },
    { value: 10, name: 'Opthalmology' },
    { value: 11, name: 'General Medicine' },
    { value: 15, name: 'Nephrology' },
  ]
  const HospitalizatioList4 = [
    { value: 1, name: 'General Surgery' },
    { value: 2, name: 'Oncology' },
    { value: 3, name: 'Neurology' },
    { value: 4, name: 'Orthopedic' },
    { value: 5, name: 'Urology' },
    { value: 6, name: 'Cardiology' },
    { value: 7, name: 'ENT' },
    { value: 8, name: 'Gynecology' },
    // { value: 9, name: 'Gastrologic' },
    { value: 10, name: 'Opthalmology' },
    { value: 11, name: 'General Medicine' },
    { value: 15, name: 'Nephrology' },
    { value: 12, name: 'OPD Others' },
    { value: 13, name: 'Investigation Others' },
    { value: 14, name: 'Day Care Others' },


  ]
  const hospitalizatioList1 = [
    { value: 12, name: 'OPD Others' },
  ]
  const HospitalizatioList2 = [
    { value: 13, name: 'Investigation Others' },
  ]
  const HospitalizatioList3 = [
    { value: 14, name: 'Day Care Others' },
  ]

  const parseDate = (dateString) => {
    if (!dateString) return;
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };
  const formatDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };
  const loadService = async () => {
    try {
      const payload = {
        status: 1,
        serviceCategoryCode: 'CBS-6'
      };
      const res = await apiService.geteServices(payload);
      if (!res.message) {
        setserviceList(res.data)
      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }
  }
  // const getprocedureList = async () => {
  //   try {
  //     const payload = {
  //     };
  //     const res = await apiService.getprocedureList(payload);
  //     if (!res.message) {
  //       setprocedureList(res.data)
  //     }
  //   } catch (error) {
  //     console.error('Error fetching enquiry list:', error);
  //   }
  // }
  const handleChange = (e) => {
    if (e.target?.id != 'date') {
      setserviceObj({
        ...serviceObj,
        [e.target.name]: e.target.value,
      })
      if (e.target.name == 'referralUserId') {


        setserviceObj(prevServiceObj => ({
          ...prevServiceObj,
          ['doctorShare']: docList.filter(doc => doc.DoctorCode === e.target.value)[0].DoctorFees
        }));
        setserviceObj(prevServiceObj => ({
          ...prevServiceObj,
          ['doctorShareType']: docList.filter(doc => doc.DoctorCode === e.target.value)[0].DoctorShareType
        }));
        setserviceObj(prevServiceObj => ({
          ...prevServiceObj,
          ['referralUserName']: docList.filter(doc => doc.DoctorCode === e.target.value)[0].DoctorName
        }));
      }
    }
    else {
      //  let date= formatDate(e.target.value)
      setserviceObj({
        ...serviceObj,
        [e.target.name]: formatDate(e.target.value),
      });

    }


    if (e.target.name == 'hospitalizationTypeId') {
      getHospitaliztionList(e.target.value)
    }


  }

  const calculatePercentage = () => {

    setserviceObj(prevServiceObj => {
      const mciPercentage = ((serviceObj?.mci || 0) / (serviceObj?.gross || 0)) * 100;
      const gmvPercentage = ((serviceObj?.amount || 0) / (serviceObj?.gross || 0)) * 100;
      const netSalesPercentage = ((serviceObj?.yieldAmount || 0) / (serviceObj?.amount || 0)) * 100;
      const doctorSharePercentage = ((serviceObj?.doctorShare || 0) / (serviceObj?.amount || 0)) * 100;
  
      return {
        ...prevServiceObj,
        ['mciPercentage']: (((serviceObj?.mci || 0) / (serviceObj?.gross || 0)) * 100).toFixed(2) || '0.00',
        ['gmvPercentage']: isNaN(gmvPercentage) ? '0.00' : gmvPercentage.toFixed(2),
        ['netSalesPercentage']: isNaN(netSalesPercentage) ? '0.00' : netSalesPercentage.toFixed(2),
        ['doctorSharePercentage']: isNaN(doctorSharePercentage) ? '0.00' : doctorSharePercentage.toFixed(2)
      };
    });
  }

  const getHospitaliztionList = async (value) => {
    if( (serviceObj?.serviceType || props?.selected?.serviceType) &&(serviceObj?.hospitalizationTypeId  ||  props?.selected?.hospitalizationTypeId ||  value )){
    let payload = {
      serviceType: serviceObj?.serviceType ? serviceObj?.serviceType : props?.selected?.serviceType,
      hospitalizationTypeId: value ? value : serviceObj?.hospitalizationTypeId ? serviceObj?.hospitalizationTypeId : props?.selected?.hospitalizationTypeId
    }
    let res = await apiService.getHospitaliztionList(payload)
    if (res?.data?.data.length) {
      setprocedureList(res.data.data)
    }
    else {
      setprocedureList([]);
    }
    }
  }
  const getDoctorList = async (e) => {
    setdocList([]);
    const payload = {

      hospitalId: props?.selected?.hospitalId,
      surgeryId:e?.target?.value ? e.target.value : props?.selected?.procedureId || serviceObj?.procedureId,
      type: 'Non-Referral',
      state: props?.selected?.hospitalState ? props?.selected?.hospitalState : serviceObj?.hospitalState ? serviceObj?.hospitalState :serviceObj?.state
    };
    if(payload.hospitalId &&payload.surgeryId && payload.state ){

    try {
    

      const res = await apiService.getMdmUserlist(payload);;
      if (!res.message) {
        setdocList(res.data.doctorList)
      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }

  }
  }
  const convertUTCToLocal = (date, format) => {
    format = format || 'MM/DD/YYYY'; // YYYY-MM-DD hh:mm:ss
    const localTime = moment.utc(date).toDate();
    return moment(localTime).format(format);
  }
  const encodeBase64File = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        console.log(baseURL.split(',')[1]);
        resolve(baseURL.split(',')[1]);
      };
      console.log(fileInfo);
    });
  }

  useEffect(() => {
    // if (props?.mode2 != 'fromList') {

    calculatePercentage()
    // }

  }, [serviceObj?.mci, serviceObj?.gross, serviceObj?.amount, serviceObj?.yieldAmount]);
  const handleCancel = () => {
    if (serviceObj?.billingStatus == 0 && serviceObj?.amount) {
      return toast.error('Please get Approval from Admin')
    }
    props.onClose()
  }
  let updateFor = false
  const saveService1 = async (e) => {
    if(e == 'U'){
e = 'C'
    if (rejectBill == true) {

      if (!serviceObj?.reasonForReject) {
        return toast.error('Enter the reason for reject billing.')

      }

    }
    let result = serviceList.filter(
      (word) => word.code == serviceObj.paymentMode
    );

    let serviceName = result[0]?.name || ''
    let ipdName = result[0]?.code || ''
    let topRecord = {}
    let lowRecord = {}
    if (!serviceObj.hospitalName) {
      return toast.error('Please Select Hospital ');
    }
    if (!serviceObj.paymentMode) {
      return toast.error('Please select Payment Mode');
    }
    if (!serviceObj.procedureId) {
      return toast.error('Please select Hospitalization Subtype');
    }
    if (roleCode != 'CE' && serviceObj.referral != 0 && serviceObj.referral != 1) {
      return toast.error('Please Select Referral');

    }
    if (roleCode != 'CE' && serviceObj.referral == 1) {
      if (!serviceObj.referralUserId || serviceObj.referralUserId == null) {
        return toast.error(' Please select the Doctor ');
      }
      // if (!serviceObj.doctorShare) {
      //   return toast.error(' Please enter Doctorshare');
      // }
    }
    // if (!serviceObj.amount) {
    //   return toast.error('Please enter the Actual amount Billed ');

    // }
    // if (!serviceObj.yieldAmount) {
    //   return toast.error('Please enter the Netsale amount ');

    // }

    if (!serviceObj.serviceSubTypeId) {
      return toast.error('Please Select Sub Service Type ');

    }
    if (!serviceObj.hospitalizationTypeId) {
      return toast.error('Please Select Hospitalization Type ');

    }


    // if (serviceObj.yieldAmount > serviceObj.amount) {
    //   return toast.error(' Billed Amount should be greater than Net Sale ');

    // }
    if (!serviceObj.gross) {
      return toast.error('Please enter gross amount ');
    }
    if (roleCode != 'CE' && !serviceObj.mci && serviceObj.mci != 0) {
      return toast.error('Please enter mci amount ');
    }
    if (serviceObj.gross && serviceObj.mci) {
      if (+serviceObj.gross < +serviceObj.mci) {
        return toast.error('MCI Amount should be less than Gross');
      }
    }
    if (!serviceObj.procedureDate) {
      return toast.error('Please Select Billing Date ');

    }
    if (!serviceObj.fromDate) {
      return toast.error('Please Select Admission From Date ');

    }
    if (!serviceObj.toDate) {
      return toast.error('Please Select Admission To Date ');

    }

    if (!billBase64 && !serviceObj.uploadBillName && props?.mode != 'Edit') {
      return toast.error('Please Upload the Bill ');
    }

    if (serviceObj.referral == 0) {
      serviceObj.referralUserId = ''
      serviceObj.referralUserName = ''

      serviceObj.doctorShare = ''
    }
    topRecord = {
      patientId: serviceObj.patientId,
      serviceApplicable: "O",
      userId: '',
      txnId: '',
      // payMode: "Partner Payment",
      serviceCode: "CHE_BILLING",
      serviceName: "eClinic billing",
      // serviceCode: ipdName,
      // serviceName: serviceName,
      discount: "",
      discountAmount: "",
      gst: "0",
      // totalAmount: serviceObj.amount,
      // curebayFee: serviceObj.amount,
      discountPecentage: 0,
      totalTaxAmount: 0,
      orderId: props?.mode == 'Edit' ? props?.selected?.orderId : serviceObj?.orderId ? serviceObj?.orderId : '',
      cheCenterId: props?.mode == 'Edit' ? props?.selected?.cheCenterId : serviceObj.cheid ? serviceObj.cheid : serviceObj.cheCenterId,
      cheCenterName: props?.mode == 'Edit' ? props?.selected?.cheCenterName : serviceObj.chehospitalName ? serviceObj.chehospitalName : serviceObj.cheCenterName,
      cheBranchId: props?.mode == 'Edit' ? props?.selected?.cheBranchId : serviceObj.chelocationId ? serviceObj.chelocationId : serviceObj.cheBranchId,
      cheBranchName: props?.mode == 'Edit' ? props?.selected?.cheBranchName : serviceObj.chelocationName ? serviceObj.chelocationName : serviceObj.cheBranchName,
      createdBy: userObj.user.code,
      modifiedBy: userObj.user.code,
      status: 1,
      userCreatedBy: serviceObj.createdBy,
      procedureId: serviceObj.procedureId,
      // procedureDate :convertUTCToLocal(serviceObj.procedureDate, 'YYYY-MM-DD hh:mm:ss'),
      // admissionFromDate :convertUTCToLocal(serviceObj.fromDate, 'YYYY-MM-DD hh:mm:ss'),
      // admissionToDate :convertUTCToLocal(serviceObj.toDate, 'YYYY-MM-DD hh:mm:ss'),
      typeOfInsurance: serviceObj?.insurence || '',
      yieldAmount: serviceObj.yieldAmount,
      sourceChannel: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" || serviceObj.rptRoleCode == 'SWASTH' ? 'Swasthya Mitra' : 'ECLINIC',
      userRoleCode: roleCode,
      swasthUserId: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" || serviceObj.rptRoleCode == 'SWASTH' ? props?.selected?.swasthUserId ? props?.selected?.swasthUserId : serviceObj.swasthUserId : null,
      swasthUserName: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" ? props?.selected?.swasthUserName : serviceObj.swasthUserName,
      reportsDetails: reportPayload.length ? reportPayload : [],
      rptRoleCode: props?.mode == 'Edit' ? props?.selected?.rptRoleCode : serviceObj.userRoleCode ? serviceObj.userRoleCode : serviceObj.rptRoleCode,
      serviceStatus: 1,
      documentType: billType,
      refTxnId: serviceObj.transactionid,
      referral: serviceObj?.referral || 0,

      referralUserId: serviceObj?.referral == 1 ? serviceObj?.referralUserId : null,
      referralUserName: serviceObj?.referral == 1 ? docList.filter(doc => doc.DoctorCode === serviceObj?.referralUserId)[0].DoctorName : null,
      doctorShare: serviceObj?.doctorShare,
      doctorShareType: serviceObj?.doctorShareType || 'Fixed',


      serviceSubTypeId: serviceObj.serviceSubTypeId || '',
      hospitalizationTypeId: serviceObj.hospitalizationTypeId || '',
      serviceEnquiryId: serviceObj.serviceEnquiryId ? serviceObj.serviceEnquiryId : serviceObj.id,
      serviceType: serviceObj.serviceType || '',
      mci: serviceObj.mci ? serviceObj.mci : 0,
      gross: serviceObj.gross,
      totalAmount: serviceObj.amount,
      curebayFee: serviceObj.amount,
      payMode: 'Partner Payment',
      partnerType: 'Hospital',
      revenueSource: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" || serviceObj.rptRoleCode == "SWASTH" ? 'Swasthya Mitra' : 'ECLINIC',
      paymentMode: serviceName,
      paymentModeCode: serviceObj.paymentMode,
      // campLead: serviceObj.campLead,

      procedureName: procedureList.filter(
        (word) => word.hospitalizationSubTypeId == serviceObj.procedureId
      )[0].hospitalizationSubType,
      serviceSubType: subServiceList.filter(
        (word) => word.value == serviceObj.serviceSubTypeId
      )[0].name,
      hospitalizationType: HospitalizatioList4.filter(
        (word) => word.value == serviceObj.hospitalizationTypeId
      )[0].name,




    }
    if (props?.mode === 'Edit') {
      topRecord.serviceEnquiryId = serviceObj.serviceEnquiryId
      topRecord.id = serviceObj.id
      topRecord.reportsName = serviceObj?.reportsName
    }
    if (serviceObj.serviceEnquiryId) {
      topRecord.id = serviceObj.id

    }
    if (roleCode == 'PRADMIN') {

      topRecord.facilityId = serviceObj.hospitalId
      topRecord.facilityLocId = serviceObj.locationId
      topRecord.facilityName = serviceObj.hospitalName
      topRecord.facilityLocName = serviceObj.locationName
      topRecord.hospitalId = serviceObj.hospitalId
      topRecord.locationId = serviceObj.locationId
      topRecord.masterServiceType = 'Hospital' + '-' + serviceName

    }
    if (serviceObj.procedureDate != undefined) {
      topRecord.procedureDate = convertUTCToLocal(new Date(serviceObj.procedureDate), 'YYYY-MM-DD hh:mm:ss')
    }
    if (serviceObj.procedureDate == undefined) {
      topRecord.procedureDate = ''
    }
    if (serviceObj.fromDate != undefined) {
      topRecord.admissionFromDate = convertUTCToLocal(new Date(serviceObj.fromDate), 'YYYY-MM-DD hh:mm:ss')
    }
    if (serviceObj.fromDate == undefined) {
      topRecord.admissionFromDate = ''
    }
    if (serviceObj.toDate != undefined) {
      topRecord.admissionToDate = convertUTCToLocal(new Date(serviceObj.toDate), 'YYYY-MM-DD hh:mm:ss')
    }
    if (serviceObj.toDate == undefined) {
      topRecord.admissionToDate = ''
    }
    if (billBase64 !== undefined) {
      topRecord.uploadBill = billBase64
    }
    // if (roleCode == 'GROUPPRADMIN') {
    topRecord.facilityId = serviceObj?.hospitalId || ''
    topRecord.facilityLocId = serviceObj.locationId || ''
    topRecord.facilityName = serviceObj?.hospitalName || ''
    topRecord.facilityLocName = serviceObj?.locationName || ''
    topRecord.hospitalId = serviceObj?.hospitalId || ''
    topRecord.locationId = serviceObj?.locationId || ''
    topRecord.masterServiceType = 'Hospital' + '-' + serviceName
    // }
    topRecord.doctorId = serviceObj.referral == 1 ? serviceObj?.referralUserId : null
    topRecord.surgeryId = +serviceObj.procedureId
    topRecord.state = props?.selected?.hospitalState  ? props?.selected?.hospitalState  : serviceObj?.hospitalState
    topRecord.type = serviceObj.referral == 0 ? 'Referral' : 'Non-Referral'
    topRecord.procedureMode = result[0].procedureMode
    // topRecord.billingStatus = roleCode == 'GROUPPRADMIN' ? 2 : roleCode == 'CE' ? 1 : roleCode == 'ACCADMIN' ? 3 : 2
    topRecord.billingStatus = (e == 'C' || e == 'U') ? serviceObj?.billingStatus : (roleCode == 'GROUPPRADMIN' ? 2 : roleCode == 'CE' ? 1 : roleCode == 'ACCADMIN' ? 3 : 1)
    topRecord.calculationFlag = e == 'C' ? 1 : 0
    if (roleCode == 'ACCADMIN') {
      topRecord.billingPublishStatus = 'W'
    }
    if ((!billBlob && props?.mode == 'Edit') || (!billBlob && serviceObj.serviceEnquiryId)) {

      topRecord.uploadBillName = serviceObj.uploadBillName
      delete topRecord.uploadBill
    }
    if ((!reportBlob && props?.mode == 'Edit') || (reportBlob.length == 0 && serviceObj.serviceEnquiryId)) {

      topRecord.reportsName = serviceObj.reportsName
      delete topRecord.reportsDetails
    }
    if (serviceObj.reportsName) {
      topRecord.reportsName = serviceObj.reportsName

    }
    let saveObj = []
    if (e === 'B') {
      topRecord.billingStatus = roleCode == 'GROUPPRADMIN' ? 2 : roleCode == 'CE' ? 1 : roleCode == 'ACCADMIN' ? 3 : 2
      if (rejectBill == true || props.selected.reject == true) {
        topRecord.billingStatus = 6
        topRecord.reasonForReject = serviceObj.reasonForReject
      }
    }
    if (e === '') {
      topRecord.billingStatus = 1
    }
    if (e === 'CS') {
      topRecord.calculationFlag = 1
      topRecord.billingStatus = serviceObj?.billingStatus ? serviceObj?.billingStatus : 0
    }
    saveObj.push(topRecord)
saveObj.forEach((element)=>{
delete element.campLead
})

    // saveObj.push(lowRecord)
    setloading(true)
    try {
      // (newValue == 0) ? await apiService.getenqCElist(payload) 
      const res = (e != 'B') ? await apiService.saveService(saveObj) : await apiService.billingChange(saveObj)
      console.log(res, 'addd')
      if (!res?.message) {
        if (props.mode != "Edit" && topRecord.billingStatus != 0) {

          setBillblob('')
          setReportBlob('')
          setReportpayload([])
          setbillBase64('')
          setbillType('')
          props.onClose()
          setloading(false)


        }
        else {

          let payload = {
            id: topRecord.billingStatus != 0 ? serviceObj.id : res.data.id,
            // roleCode:roleCode

          }
          const res1 = await apiService.getServicesBillList(payload);
         updateFor = true
          setloading(false)
          setBillblob('')
          setbillBase64('')
          setbillType('')
          setReportpayload([])
          let service = res1.data[0]
          service.patientName = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.patientName : props?.selected?.name
          service.hospitalName = service.hospitalName
          service.procedureId = service.procedureId
          service.paymentMode = service.paymentModeCode
          service.amount = service.totalAmount
          service.insurence = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.typeOfInsurance : ''
          service.transactionid = props?.mode == 'Edit' ? service.refTxnId : ''
          if ((props?.mode == 'Edit' || topRecord.billingStatus == 0)) {
            service.fromDate = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? formatDate(service.admissionFromDate) : formatDate(props?.selected?.procedurePlannedStartDate)
            service.toDate = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? formatDate(service.admissionToDate) : formatDate(props?.selected?.procedurePlannedEndDate)
            service.procedureDate = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? formatDate(service.procedureDate) : formatDate(props?.selected?.procedurePlannedDate)
          }
          service.serviceType = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.serviceType : props?.selected?.serviceType
          service.serviceSubTypeId = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.serviceSubTypeId : props?.selected?.serviceSubTypeId
          service.hospitalizationTypeId = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.hospitalizationTypeId : props?.selected?.hospitalizationTypeId
          if (service?.reportsName) {

            if (service?.reportsName.includes(',')) {
              let x = []
              x = service?.reportsName.split(',')
              setviewReport(x)

            }
            else {
              let y = []
              y.push(service?.reportsName)
              setviewReport(y)

            }
          }
          setReportBlob([])
          setserviceObj(service)
          setenqObj(props?.selected)
        }
        props.onCancel()
        setloading(false)

      }
      else {
        setloading(false)

        return toast.error(res?.response?.data?.details[0] ? res.response.data.details[0] : 'Something went Wrong');



      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);

    }

    console.log(saveObj)
  
   
  }
   }
   useEffect(() => {
    if (updateFor == true) {
      saveService('U');
    }
  }, [serviceObj]);
  const saveService = async (e) => {

    if (rejectBill == true) {

      if (!serviceObj?.reasonForReject) {
        return toast.error('Enter the reason for reject billing.')

      }

    }
    let result = serviceList.filter(
      (word) => word.code == serviceObj.paymentMode
    );

    let serviceName = result[0]?.name || ''
    let ipdName = result[0]?.code || ''
    let topRecord = {}
    let lowRecord = {}
    if (!serviceObj.hospitalName) {
      return toast.error('Please Select Hospital ');
    }
    if (!serviceObj.paymentMode) {
      return toast.error('Please select Payment Mode');
    }
    if (!serviceObj.procedureId) {
      return toast.error('Please select Hospitalization Subtype');
    }
    if (roleCode != 'CE' && serviceObj.referral != 0 && serviceObj.referral != 1) {
      return toast.error('Please Select Referral');

    }
    if (roleCode != 'CE' && serviceObj.referral == 1) {
      if (!serviceObj.referralUserId || serviceObj.referralUserId == null) {
        return toast.error(' Please select the Doctor ');
      }
      // if (!serviceObj.doctorShare) {
      //   return toast.error(' Please enter Doctorshare');
      // }
    }
    // if (!serviceObj.amount) {
    //   return toast.error('Please enter the Actual amount Billed ');

    // }
    // if (!serviceObj.yieldAmount) {
    //   return toast.error('Please enter the Netsale amount ');

    // }

    if (!serviceObj.serviceSubTypeId) {
      return toast.error('Please Select Sub Service Type ');

    }
    if (!serviceObj.hospitalizationTypeId) {
      return toast.error('Please Select Hospitalization Type ');

    }


    // if (serviceObj.yieldAmount > serviceObj.amount) {
    //   return toast.error(' Billed Amount should be greater than Net Sale ');

    // }
    if (!serviceObj.gross) {
      return toast.error('Please enter gross amount ');
    }
    if (roleCode != 'CE' && !serviceObj.mci && serviceObj.mci != 0) {
      return toast.error('Please enter mci amount ');
    }
    if (serviceObj.gross && serviceObj.mci) {
      if (+serviceObj.gross < +serviceObj.mci) {
        return toast.error('MCI Amount should be less than Gross');
      }
    }
    if (!serviceObj.procedureDate) {
      return toast.error('Please Select Billing Date ');

    }
    if (!serviceObj.fromDate) {
      return toast.error('Please Select Admission From Date ');

    }
    if (!serviceObj.toDate) {
      return toast.error('Please Select Admission To Date ');

    }

    if (!billBase64 && !serviceObj.uploadBillName && props?.mode != 'Edit') {
      return toast.error('Please Upload the Bill ');
    }
    // if(roleCode == 'GROUPPRADMIN'){

    // if (!serviceObj.code) {
    //   return toast.error('Please Select Hospital '); 
    // }}
    // if (!serviceObj.service) {
    //   return toast.error('Please Select Service '); 
    // }

    if (serviceObj.referral == 0) {
      serviceObj.referralUserId = ''
      serviceObj.referralUserName = ''

      serviceObj.doctorShare = ''
    }
    topRecord = {
      patientId: serviceObj.patientId,
      serviceApplicable: "O",
      userId: '',
      txnId: '',
      // payMode: "Partner Payment",
      serviceCode: "CHE_BILLING",
      serviceName: "eClinic billing",
      // serviceCode: ipdName,
      // serviceName: serviceName,
      discount: "",
      discountAmount: "",
      gst: "0",
      // totalAmount: serviceObj.amount,
      // curebayFee: serviceObj.amount,
      discountPecentage: 0,
      totalTaxAmount: 0,
      orderId: props?.mode == 'Edit' ? props?.selected?.orderId : serviceObj?.orderId ? serviceObj?.orderId : '',
      cheCenterId: props?.mode == 'Edit' ? props?.selected?.cheCenterId : serviceObj.cheid ? serviceObj.cheid : serviceObj.cheCenterId,
      cheCenterName: props?.mode == 'Edit' ? props?.selected?.cheCenterName : serviceObj.chehospitalName ? serviceObj.chehospitalName : serviceObj.cheCenterName,
      cheBranchId: props?.mode == 'Edit' ? props?.selected?.cheBranchId : serviceObj.chelocationId ? serviceObj.chelocationId : serviceObj.cheBranchId,
      cheBranchName: props?.mode == 'Edit' ? props?.selected?.cheBranchName : serviceObj.chelocationName ? serviceObj.chelocationName : serviceObj.cheBranchName,
      createdBy: userObj.user.code,
      modifiedBy: userObj.user.code,
      status: 1,
      userCreatedBy: serviceObj.createdBy,
      procedureId: serviceObj.procedureId,
      // procedureDate :convertUTCToLocal(serviceObj.procedureDate, 'YYYY-MM-DD hh:mm:ss'),
      // admissionFromDate :convertUTCToLocal(serviceObj.fromDate, 'YYYY-MM-DD hh:mm:ss'),
      // admissionToDate :convertUTCToLocal(serviceObj.toDate, 'YYYY-MM-DD hh:mm:ss'),
      typeOfInsurance: serviceObj?.insurence || '',
      yieldAmount: serviceObj.yieldAmount,
      sourceChannel: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" || serviceObj.rptRoleCode == 'SWASTH' ? 'Swasthya Mitra' : 'ECLINIC',
      userRoleCode: roleCode,
      swasthUserId: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" || serviceObj.rptRoleCode == 'SWASTH' ? props?.selected?.swasthUserId ? props?.selected?.swasthUserId : serviceObj.swasthUserId : null,
      swasthUserName: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" ? props?.selected?.swasthUserName : serviceObj.swasthUserName,
      reportsDetails: reportPayload.length ? reportPayload : [],
      rptRoleCode: props?.mode == 'Edit' ? props?.selected?.rptRoleCode : serviceObj.userRoleCode ? serviceObj.userRoleCode : serviceObj.rptRoleCode,
      serviceStatus: 1,
      documentType: billType,
      refTxnId: serviceObj.transactionid,
      referral: serviceObj?.referral || 0,

      referralUserId: serviceObj?.referral == 1 ? serviceObj?.referralUserId : null,
      referralUserName: serviceObj?.referral == 1 ? docList.filter(doc => doc.DoctorCode === serviceObj?.referralUserId)[0].DoctorName : null,
      doctorShare: serviceObj?.doctorShare,
      doctorShareType: serviceObj?.doctorShareType || 'Fixed',


      serviceSubTypeId: serviceObj.serviceSubTypeId || '',
      hospitalizationTypeId: serviceObj.hospitalizationTypeId || '',
      serviceEnquiryId: serviceObj.serviceEnquiryId ? serviceObj.serviceEnquiryId : serviceObj.id,
      serviceType: serviceObj.serviceType || '',
      mci: serviceObj.mci ? serviceObj.mci : 0,
      gross: serviceObj.gross,
      totalAmount: serviceObj.amount,
      curebayFee: serviceObj.amount,
      payMode: 'Partner Payment',
      partnerType: 'Hospital',
      revenueSource: serviceObj.userRoleCode == "SWASTH" || props?.selected?.rptRoleCode == "SWASTH" || serviceObj.rptRoleCode == "SWASTH" ? 'Swasthya Mitra' : 'ECLINIC',
      paymentMode: serviceName,
      paymentModeCode: serviceObj.paymentMode,
      // campLead: serviceObj.campLead,

      procedureName: procedureList.filter(
        (word) => word.hospitalizationSubTypeId == serviceObj.procedureId
      )[0].hospitalizationSubType,
      serviceSubType: subServiceList.filter(
        (word) => word.value == serviceObj.serviceSubTypeId
      )[0].name,
      hospitalizationType: HospitalizatioList4.filter(
        (word) => word.value == serviceObj.hospitalizationTypeId
      )[0].name,




    }
    if (props?.mode === 'Edit') {
      topRecord.serviceEnquiryId = serviceObj.serviceEnquiryId
      topRecord.id = serviceObj.id
      topRecord.reportsName = serviceObj?.reportsName
    }
    if (serviceObj.serviceEnquiryId) {
      topRecord.id = serviceObj.id

    }
    if (roleCode == 'PRADMIN') {

      topRecord.facilityId = serviceObj.hospitalId
      topRecord.facilityLocId = serviceObj.locationId
      topRecord.facilityName = serviceObj.hospitalName
      topRecord.facilityLocName = serviceObj.locationName
      topRecord.hospitalId = serviceObj.hospitalId
      topRecord.locationId = serviceObj.locationId
      topRecord.masterServiceType = 'Hospital' + '-' + serviceName

    }
    if (serviceObj.procedureDate != undefined) {
      topRecord.procedureDate = convertUTCToLocal(new Date(serviceObj.procedureDate), 'YYYY-MM-DD hh:mm:ss')
    }
    if (serviceObj.procedureDate == undefined) {
      topRecord.procedureDate = ''
    }
    if (serviceObj.fromDate != undefined) {
      topRecord.admissionFromDate = convertUTCToLocal(new Date(serviceObj.fromDate), 'YYYY-MM-DD hh:mm:ss')
    }
    if (serviceObj.fromDate == undefined) {
      topRecord.admissionFromDate = ''
    }
    if (serviceObj.toDate != undefined) {
      topRecord.admissionToDate = convertUTCToLocal(new Date(serviceObj.toDate), 'YYYY-MM-DD hh:mm:ss')
    }
    if (serviceObj.toDate == undefined) {
      topRecord.admissionToDate = ''
    }
    if (billBase64 !== undefined) {
      topRecord.uploadBill = billBase64
    }
    // if (roleCode == 'GROUPPRADMIN') {
    topRecord.facilityId = serviceObj?.hospitalId || ''
    topRecord.facilityLocId = serviceObj.locationId || ''
    topRecord.facilityName = serviceObj?.hospitalName || ''
    topRecord.facilityLocName = serviceObj?.locationName || ''
    topRecord.hospitalId = serviceObj?.hospitalId || ''
    topRecord.locationId = serviceObj?.locationId || ''
    topRecord.masterServiceType = 'Hospital' + '-' + serviceName
    // }
    topRecord.doctorId = serviceObj.referral == 1 ? serviceObj?.referralUserId : null
    topRecord.surgeryId = +serviceObj.procedureId
    topRecord.state = props?.selected?.hospitalState  ? props?.selected?.hospitalState  : serviceObj?.hospitalState
    topRecord.type = serviceObj.referral == 0 ? 'Referral' : 'Non-Referral'
    topRecord.procedureMode = result[0].procedureMode
    // topRecord.billingStatus = roleCode == 'GROUPPRADMIN' ? 2 : roleCode == 'CE' ? 1 : roleCode == 'ACCADMIN' ? 3 : 2
    topRecord.billingStatus = (e == 'C' || e == 'U') ? serviceObj?.billingStatus : (roleCode == 'GROUPPRADMIN' ? 2 : roleCode == 'CE' ? 1 : roleCode == 'ACCADMIN' ? 3 : 1)
    topRecord.calculationFlag = e == 'C' ? 1 : 0
    if (roleCode == 'ACCADMIN') {
      topRecord.billingPublishStatus = 'W'
    }
    if ((!billBlob && props?.mode == 'Edit') || (!billBlob && serviceObj.serviceEnquiryId)) {

      topRecord.uploadBillName = serviceObj.uploadBillName
      delete topRecord.uploadBill
    }
    if ((!reportBlob && props?.mode == 'Edit') || (reportBlob.length == 0 && serviceObj.serviceEnquiryId)) {

      topRecord.reportsName = serviceObj.reportsName
      delete topRecord.reportsDetails
    }
    if (serviceObj.reportsName) {
      topRecord.reportsName = serviceObj.reportsName

    }
    let saveObj = []
    if (e === 'B') {
      topRecord.billingStatus = roleCode == 'GROUPPRADMIN' ? 2 : roleCode == 'CE' ? 1 : roleCode == 'ACCADMIN' ? 3 : 2
      if (rejectBill == true || props.selected.reject == true) {
        topRecord.billingStatus = 6
        topRecord.reasonForReject = serviceObj.reasonForReject
      }
    }
    if (e === '') {
      topRecord.billingStatus = 1
    }
    if (e === 'CS') {
      topRecord.calculationFlag = 1
      topRecord.billingStatus = serviceObj?.billingStatus ? serviceObj?.billingStatus : 0
    }
    saveObj.push(topRecord)
saveObj.forEach((element)=>{
delete element.campLead
})

    // saveObj.push(lowRecord)
    setloading(true)
    try {
      // (newValue == 0) ? await apiService.getenqCElist(payload) 
      const res = (e != 'B') ? await apiService.saveService(saveObj) : await apiService.billingChange(saveObj)
      console.log(res, 'addd')
      if (!res?.message) {
        if (props.mode != "Edit" && topRecord.billingStatus != 0) {

          toast.success('Service Added Successfully')
          setBillblob('')
          setReportBlob('')
          setReportpayload([])
          setbillBase64('')
          setbillType('')
          props.onClose()
          setloading(false)


        }
        else {
          toast.success('Service Updated Successfully')

          let payload = {
            id: topRecord.billingStatus != 0 ? serviceObj.id : res.data.id,
            // roleCode:roleCode

          }
          const res1 = await apiService.getServicesBillList(payload);
          setloading(false)
          setBillblob('')
          setbillBase64('')
          setbillType('')
          setReportpayload([])
          let service = res1.data[0]
          service.patientName = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.patientName : props?.selected?.name
          service.hospitalName = service.hospitalName
          service.procedureId = service.procedureId
          service.paymentMode = service.paymentModeCode
          service.amount = service.totalAmount
          service.insurence = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.typeOfInsurance : ''
          service.transactionid = props?.mode == 'Edit' ? service.refTxnId : ''
          if ((props?.mode == 'Edit' || topRecord.billingStatus == 0)) {
            service.fromDate = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? formatDate(service.admissionFromDate) : formatDate(props?.selected?.procedurePlannedStartDate)
            service.toDate = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? formatDate(service.admissionToDate) : formatDate(props?.selected?.procedurePlannedEndDate)
            service.procedureDate = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? formatDate(service.procedureDate) : formatDate(props?.selected?.procedurePlannedDate)
          }
          service.serviceType = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.serviceType : props?.selected?.serviceType
          service.serviceSubTypeId = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.serviceSubTypeId : props?.selected?.serviceSubTypeId
          service.hospitalizationTypeId = (props?.mode == 'Edit' || topRecord.billingStatus == 0) ? service.hospitalizationTypeId : props?.selected?.hospitalizationTypeId
          if (service?.reportsName) {

            if (service?.reportsName.includes(',')) {
              let x = []
              x = service?.reportsName.split(',')
              setviewReport(x)

            }
            else {
              let y = []
              y.push(service?.reportsName)
              setviewReport(y)

            }
          }
          setReportBlob([])
          setserviceObj(service)
          setenqObj(props?.selected)
        }
        props.onCancel()
        setloading(false)

      }
      else {
        setloading(false)

        return toast.error(res?.response?.data?.message ? res.response.data.message: 'Something went Wrong');



      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);

    }
    updateFor = false
    console.log(saveObj)
  }
  const uploadBill = async (e) => {
    setBillblob('')
    setbillBase64((''))
    setbillType('')
    setBillblob(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0].size > 1024000) {
      setBillblob('')
      toast("please reduce the file size to below 1mb and upload");
      return;
    }
    let b64File = await encodeBase64File(e.target.files[0]);
    setbillBase64(b64File);
    setbillType(e.target.files[0].type.split("/")[1]);
    console.log(billBase64, 'bill')
  };

  const uploadReport = async (e) => {
    let blbReport = {
      blobUrl: URL.createObjectURL(e.target.files[0]),
      name: e.target.files[0].name
    }
    let x = []
    x = []
    x.push(blbReport)
    // setReportBlob(x);
    setReportBlob(prevreportBlob => ([...prevreportBlob, ...x]));

    console.log(reportBlob, 'report')
    if (e.target.files[0].size > 1024000) {
      toast("please reduce the file size to below 1mb and upload");
      return;
    }
    let b64File = await encodeBase64File(e.target.files[0]);
    let report = {
      documentType: e.target.files[0].type.split("/")[1],

      documentContent: b64File
    }
    let y = []
    y = reportPayload || []
    y.push(report)

    setReportpayload(y);
    console.log(reportPayload, 'report')


  };
  useImperativeHandle(ref, () => ({

    getValue: () => serviceObj,
    fromParent

  }));
  const fromParent = (e) => {
    setTimeout(() => {
      saveService('B')

    }, 0);
  }
  const deleteBlob = (index) => {
    let reporblob = [...reportBlob];
    reporblob.splice(index, 1);
    setReportBlob(reporblob)

  }
  useEffect(() => {
    // if (props?.mode2 != 'fromList') {

    getDoctorList()
    getHospitaliztionList()
    loadService()
    let service = props?.selected
    if (roleCode == 'CE') {
      service.referral = 0
    }
    service.patientName = props?.mode == 'Edit' ? props?.selected?.patientName : props?.selected?.name
    service.hospitalName = props?.selected?.hospitalName
    service.procedureId = props?.selected?.procedureId
    service.paymentMode = props?.selected?.paymentModeCode
    service.amount = props?.selected?.totalAmount
    service.insurence = props?.mode == 'Edit' ? props?.selected?.typeOfInsurance : ''
    service.transactionid = props?.mode == 'Edit' ? props?.selected?.refTxnId : ''
    if (props?.mode == 'Edit') {
      service.fromDate = props?.mode == 'Edit' ? formatDate(props?.selected?.admissionFromDate) : formatDate(props?.selected?.procedurePlannedStartDate)
      service.toDate = props?.mode == 'Edit' ? formatDate(props?.selected?.admissionToDate) : formatDate(props?.selected?.procedurePlannedEndDate)
      service.procedureDate = props?.mode == 'Edit' ? formatDate(props?.selected?.procedureDate) : formatDate(new Date())
    }
    service.serviceType = props?.mode == 'Edit' ? props?.selected?.serviceType : props?.selected?.serviceType
    service.serviceSubTypeId = props?.mode == 'Edit' ? props?.selected?.serviceSubTypeId : props?.selected?.serviceSubTypeId
    service.hospitalizationTypeId = props?.mode == 'Edit' ? service.hospitalizationTypeId : props?.selected?.hospitalizationTypeId
    service.gross = props?.mode == 'Edit' ? props?.selected?.gross : props?.selected?.gross
    service.mci = props?.mode == 'Edit' ? props?.selected?.mci : props?.selected?.mci
    service.reportsName = props?.mode == 'Edit' ? props?.selected?.reportsName : props?.selected?.reportsName
    service.procedureDate = props?.mode == 'Edit' ? formatDate(props?.selected?.procedureDate) : formatDate(new Date())

    // service.campLead = props?.selected?.campLead


    if (props?.mode == 'Edit' && props?.selected.reportsName) {
      if (props?.selected.reportsName.includes(',')) {
        let x = []
        x = props?.selected.reportsName.split(',')
        setviewReport(x)

      }
      else {
        let y = []
        y.push(props?.selected?.reportsName)
        setviewReport(y)

      }
    }
    setserviceObj(service)
    // setenqObj(service)
    setenqObj(props?.selected)
  // }
    // serviceName =  props?.selected?.enquiryServiceName,
    // ipdName =  props?.selected?.enquiryServiceCode
  }, [props?.selected]);


  const billingChange = async () => {

    let payload = {
      billingStatus: roleCode == 'GROUPPRADMIN' ? 2 : roleCode == 'CE' ? 1 : roleCode == 'ACCADMIN' ? 3 : 2
    }
    const res = await apiService.saveService(payload);

  }
  const rejectBilling = () => {
    setrejectBill(true)
    if (rejectBill == true) {
      saveService('B');
    }
  }
  useEffect(() => {
    // if (props?.mode2 != 'fromList') {
    if (rejectBill == true) {
      saveService('B');
    }
  // }
  }, [rejectBill == true]);
  return (
    <>
      <Backdrop
        sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress size={80} color="inherit" />
      </Backdrop>
      <div className="grid mt-4  grid-cols-3 gap-4">
        <div>
          <label htmlFor="emailId">Patient Name<span className="text-red-500">*</span></label>
          <InputText id="emailId" onChange={(e) => handleChange(e)} name='patientName' disabled value={serviceObj?.patientName} className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="mobile">Hospital<span className="text-red-500">*</span></label>
          <InputText name='hospitalName' disabled onChange={(e) => handleChange(e)} value={serviceObj?.hospitalName} id="mobile" className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        {/* Repeat the above div blocks for the other fields */}

        <div>
          <label htmlFor="name">Payment Mode<span className="text-red-500">*</span></label>
          {/* <InputText  value={selectedRow.hospitalName}  id="name" className="border border-gray-300 rounded-md p-2 w-full" /> */}
          <Dropdown
            optionLabel="name" // This tells Dropdown how to display the options
            placeholder="Select Payment Mode"
            options={serviceList}
            name='paymentMode' value={serviceObj?.paymentMode} optionValue='code'
            className="border border-gray-300 rounded-md  w-full"
            disabled={serviceObj?.billingStatus == 3}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <label htmlFor="name">Service Type<span className="text-red-500">*</span></label>
          {/* <InputText id="name"  value={selectedRow.serviceType}  className="border border-gray-300 rounded-md p-2 w-full" /> */}
          <Dropdown
            disabled={serviceObj?.billingStatus == 3}

            value={serviceObj?.serviceType}
            options={serviceTypeDs}
            optionLabel="value" // This tells Dropdown how to display the options
            placeholder="Select a Service Type"
            optionValue='id'
            name='serviceType'
            editable
            className="border border-gray-300 rounded-md  w-full"
            onChange={(e) => { 
              handleChange(e);
          }}              />

        </div>
        <div>
          <label htmlFor="name">Service Sub Type<span className="text-red-500">*</span></label>
          {/* <InputText  value={selectedRow.hospitalName}  id="name" className="border border-gray-300 rounded-md p-2 w-full" /> */}
          <Dropdown
            disabled={serviceObj?.billingStatus == 3}

            optionLabel="name" // This tells Dropdown how to display the options
            placeholder="Select Service Sub Type"
            options={subServiceList}
            name='serviceSubTypeId' value={+serviceObj?.serviceSubTypeId} optionValue='value'
            className="border border-gray-300 rounded-md w-full"
            // disabled={mode == 'View'} 
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <label htmlFor="dob">Hospitalization Type<span className="text-red-500">*</span></label>
          <Dropdown
            disabled={serviceObj?.billingStatus == 3}

            options={+serviceObj?.serviceType == 8 ? HospitalizatioList : +serviceObj?.serviceType == 9 ? hospitalizatioList1 : +serviceObj?.serviceObj == 10 ? HospitalizatioList2 : +serviceObj?.serviceObj == 11 ? HospitalizatioList3 : []}

            optionLabel="name" // This tells Dropdown how to display the options
            placeholder="Select Hospitalization Type"
            name='hospitalizationTypeId' value={+serviceObj?.hospitalizationTypeId} optionValue='value'
            onChange={(e) => handleChange(e)}
            className="border border-gray-300 rounded-md  w-full"
          // disabled={mode == 'View'} 
          />
        </div>
        <div>
          <label htmlFor="dob">Hospitalization Sub Type<span className="text-red-500">*</span></label>
          <Dropdown
            disabled={serviceObj?.billingStatus == 3}

            options={procedureList}

            optionLabel="hospitalizationSubType" // This tells Dropdown how to display the options
            placeholder="Select Hospitalization Sub Type"
            name='procedureId' value={+serviceObj?.procedureId} optionValue='hospitalizationSubTypeId'
            onChange={(e) => { 
              handleChange(e);
              getDoctorList(e);
          }}             className="border border-gray-300 rounded-md  w-full"
          // disabled={mode == 'View'} 
          />
        </div>
        {(+serviceObj?.procedureId && +serviceObj?.procedureId > 79 && +serviceObj?.procedureId < 95) && <div>
          <label htmlFor="address">Hospitalization Subtype Description
          </label>
          <InputText name="procedureNameDescription" disabled={serviceObj?.billingStatus == 3}
            onChange={(e) => handleChange(e)} value={serviceObj.procedureNameDescription} className="border border-gray-300 rounded-md p-2 w-full" />
        </div>}
        {/* Repeat the above div blocks for the other fields */}

        <div>
          <label htmlFor="mobile">Gross Amount<span className="text-red-500">*</span></label>
          <InputText id="mobile" name='gross' onChange={(e) => handleChange(e)} keyfilter="num" value={serviceObj?.gross} className="border border-gray-300 rounded-md p-2 w-full" disabled={serviceObj?.billingStatus == 3}
          />
        </div>
        <div>
          <label htmlFor="mobile">MCI Amount {roleCode != 'CE' && <span className="text-red-500">*</span>}</label>
          <InputText id="mobile" disabled={serviceObj?.billingStatus == 3}
            name='mci' onChange={(e) => handleChange(e)} value={serviceObj?.mci} keyfilter="num" className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="mobile">Actual amount Billed</label>
          <InputText id="mobile" disabled name='amount' onChange={(e) => handleChange(e)} value={serviceObj?.amount} className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="name">Referral{roleCode != 'CE' && <span className="text-red-500">*</span>}</label>
          {/* <InputText  value={selectedRow.hospitalName}  id="name" className="border border-gray-300 rounded-md p-2 w-full" /> */}
          <Dropdown
            optionLabel="name" // This tells Dropdown how to display the options
            placeholder="Select Referral"
            options={referalList}
            onChange={(e) => { 
              handleChange(e);
              getDoctorList(+serviceObj?.procedureId);
          }}  
            name='referral' value={serviceObj?.referral} optionValue='value'
            className="border border-gray-300 rounded-md  w-full"
            disabled={serviceObj?.billingStatus == 3}

          // disabled={mode == 'View'} 
          />
        </div>
        {serviceObj?.referral == 1 && <div>
          <label htmlFor="dob">Doctors List<span className="text-red-500">*</span></label>
          <Dropdown
            options={docList}
            disabled={serviceObj?.billingStatus == 3}

            optionLabel="DoctorName" // This tells Dropdown how to display the options
            placeholder="Select a Doctor"
            name='referralUserId' value={serviceObj?.referralUserId} optionValue='DoctorCode'
            className="border border-gray-300 rounded-md  w-full"
            // disabled={mode == 'View'} 
            onChange={(e) => handleChange(e)}
            filter
          />
        </div>
        }
        {/* Repeat the above div blocks for the other fields */}

        {serviceObj?.referral == 1 && <div>
          <label htmlFor="emailId">Doctor Share</label>
          <InputText id="emailId" name='doctorShare' disabled onChange={(e) => handleChange(e)} value={serviceObj?.doctorShare} className="border border-gray-300 rounded-md p-2 w-full" />
        </div>}
        {serviceObj?.referral == 1 && <div>
          <label htmlFor="emailId">Doctor ShareType</label>
          <InputText id="emailId" name='doctorShareType' disabled onChange={(e) => handleChange(e)} value={serviceObj?.doctorShareType} className="border border-gray-300 rounded-md p-2 w-full" />
        </div>}

        <div>
          <label htmlFor="emailId">Net Sale</label>
          <InputText id="emailId" name='yieldAmount' onChange={(e) => handleChange(e)} value={serviceObj?.yieldAmount} disabled className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="emailId">MCI %</label>
          <InputText id="emailId" name='mciPercentage' onChange={(e) => handleChange(e)} value={serviceObj?.mciPercentage} disabled className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="gmvPercentage">GMV %</label>
          <InputText id="gmvPercentage" name='gmvPercentage' onChange={(e) => handleChange(e)} value={serviceObj?.gmvPercentage} disabled className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="netSalesPercentage">Net Sales %</label>
          <InputText id="netSalesPercentage" name='netSalesPercentage' onChange={(e) => handleChange(e)} value={serviceObj?.netSalesPercentage} disabled className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="doctorSharePercentage">Doctor Share %</label>
          <InputText id="doctorSharePercentage" name='doctorSharePercentage' onChange={(e) => handleChange(e)} value={serviceObj?.doctorSharePercentage} disabled className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        {/* <div>
          <label htmlFor="mobile">Insurance</label>
          <InputText id="mobile" name='insurence' disabled={serviceObj?.billingStatus == 3}
            onChange={(e) => handleChange(e)} value={serviceObj?.insurence} className="border border-gray-300 rounded-md p-2 w-full" />
        </div>
        <div>
          <label htmlFor="emailId">Ref Id</label>
          <InputText id="emailId" name='transactionid' disabled={serviceObj?.billingStatus == 3}
            onChange={(e) => handleChange(e)} value={serviceObj?.transactionid} className="border border-gray-300 rounded-md p-2 w-full" />
        </div> */}

        {/* Repeat the above div blocks for the other fields */}

        <div>
          <label htmlFor="dob">Admission From Date<span className="text-red-500">*</span></label>
          <Calendar id="date"
            className="border border-gray-300 rounded-md p-2 w-full" disabled={serviceObj?.billingStatus == 3}
            onChange={(e) => handleChange(e)} name='fromDate' value={parseDate(serviceObj?.fromDate)} dateFormat="dd/mm/yy" style={{ height: '40px' }} o />
        </div>
        <div>
          <label htmlFor="dob">Admisssion To Date<span className="text-red-500">*</span></label>
          <Calendar id="date"
            className="border border-gray-300 rounded-md p-2 w-full" disabled={serviceObj?.billingStatus == 3}
            onChange={(e) => handleChange(e)} name='toDate' value={parseDate(serviceObj?.toDate)} dateFormat="dd/mm/yy" style={{ height: '40px' }} />
        </div>
        <div>
          <label htmlFor="dob">Billing Date<span className="text-red-500">*</span></label>
          <Calendar
            className="border border-gray-300 rounded-md p-2 w-full" id='date'
            onChange={(e) => handleChange(e)} name='procedureDate' disabled={serviceObj?.billingStatus == 3}
            value={serviceObj?.procedureDate ? parseDate(serviceObj.procedureDate) : new Date()} dateFormat="dd/mm/yy" style={{ height: '40px' }} />
        </div>

        {(rejectBill == true || props?.selected?.reasonForReject) && <div>
          <label htmlFor="dob">Reason For Reject<span className="text-red-500">*</span></label>
          <div>
            <InputText id="reasonForReject" name='reasonForReject' onChange={(e) => handleChange(e)} value={serviceObj?.reasonForReject} className="border border-gray-300 rounded-md p-2 w-full" />
          </div>
        </div>}
        <div className="grid grid-cols-3 gap-3 h-10 mt-5 ">

          {/* disabled={(roleCode!='ACCADMIN'&& props?.selected?.billingStatus != 2) || ((roleCode =='PRADMIN' || roleCode =='GROUPPRADMIN')&&props?.selected?.billingStatus == 3)} */}
          {props?.mode == 'Edit' && <button className="  bg-[#004172] text-white rounded-md w-full"
            disabled={serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5}
            style={{ opacity: serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5 ? 0.5 : 1 }}
            // disabled={(roleCode == 'ACCADMIN' && ( serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5)) || (props.tab == 1 && serviceObj?.billingStatus != 3) }
            // style={{ opacity:((roleCode == 'ACCADMIN' && (serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5)) || (props.tab == 1 && serviceObj?.billingStatus != 3)) ? 0.5 : 1 }}
            onClick={() => saveService1('U')}>
            Update
          </button>}

          <button className="border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md  w-full"
            disabled={(roleCode == 'ACCADMIN' && (serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5)) || props.tab == 1}
            style={{ opacity: ((roleCode == 'ACCADMIN' && (serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5)) || props.tab == 1) ? 0.5 : 1 }}
            onClick={() => saveService(props?.mode == 'Edit' ? 'C' : 'CS')}>
            {props?.mode == 'Edit' ? 'Calculation' : 'Save & Calculate '}
          </button>

          {roleCode == 'ACCADMIN' && <button className="  bg-[#004172] text-white rounded-md w-full"
            style={{ opacity: roleCode == 'ACCADMIN' && serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 6 || props.tab == 1 ? 0.5 : 1 }}
            disabled={roleCode == 'ACCADMIN' && serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5 || serviceObj?.billingStatus == 6 || props.tab == 1}
            onClick={() => rejectBilling()}
          >
            Reject Billing
          </button>}

        </div>
        {/* Repeat the above div blocks for the other fields */}
      </div>
      <div className='grid grid-cols-3 gap-4 mt-5'>
        <div className='flex gap-3'>
          <div className='mt-2'>

            <label htmlFor="bill-upload" className=" flex justify-items-center gap-2 text-[#004172] font-bold ">
              {/* block text-center w-full */}
              <FaUpload className='text-[#004172] mt-1' />
              Upload Bill
            </label>
            <input
              disabled={props.tab == 1}
              id="bill-upload"
              type="file"
              accept=".pdf, image/*" // Accept PDF files and images of any type
              className="hidden  w-full "
              onChange={e => uploadBill(e)}
            />
          </div>
          <div>
            {/* Button for viewing bill */}
            {/* {reportBlob.map((notifi) => (
              <span>
                <div class="w-full mr-3">{notifi.fileName}</div> */}


            {
              (billBlob || serviceObj?.uploadBillName) && <a href={billBlob ? billBlob : serviceObj?.uploadBillName ? `${process.env.REACT_APP_IMAGE_URL + serviceObj?.uploadBillName}` : ''} target="_blank" ><FaEye className="text-[#6fb8ef] mt-3 mr-3" />
              </a>
            }
            {/* </span>))} */}
          </div>
        </div>
        {/* {props?.mode != 'Edit' ?  */}
        <div className='grid-cols-1'>
          <div className='mt-2 ' >
            {/* Button for uploading report */}
            <label htmlFor="report-upload" className="flex justify-items-center gap-2 text-[#004172] font-bold">
              <FaUpload className='text-[#004172] mt-1' />

              Upload Report
            </label>
            <input
              id="report-upload"
              type="file"
              accept=".pdf, image/*" // Accept PDF files and images of any type
              className="hidden"
              onChange={e => uploadReport(e)}
            />
          </div>
          <div>
            {reportBlob && reportBlob.map((notifi, index) => (
              <>
                <div class=" flex w-full"><a href={notifi.blobUrl} target="_blank" >{notifi.name}</a>
                  <FaTrash class="ml-3 mt-2" onClick={e => deleteBlob(index)} />
                </div>
              </>
            ))
            }


          </div>
        </div>
        {/* : */}
        <div className='mt-2 ' >
          <label htmlFor="dob" className='text-[#004172] font-bold'>View Reports</label>
          <div className="flex w-full mt-3 ml-2">
            <div className='grid grid-cols-3'>
              {viewReport && viewReport.map((notifi, index) => (
                <div >
                  <a key={index} href={`${process.env.REACT_APP_IMAGE_URL + notifi}`} target="_blank" className="mr-3" style={{ textDecoration: 'none', color: 'black' }}
                    onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                    onMouseOut={(e) => e.target.style.textDecoration = 'none'}>{`Rep-${index + 1}`}</a>

                </div>
              ))
              }
            </div>
          </div>
        </div>

        {/* } */}
      </div>

      <div className="grid mt-24 grid-cols-2 gap-4">
        {/* disabled={(roleCode!='ACCADMIN'&& props?.selected?.billingStatus != 2) || ((roleCode =='PRADMIN' || roleCode =='GROUPPRADMIN')&&props?.selected?.billingStatus == 3)} */}
        <button className=" h-9  bg-[#004172] text-white rounded-md w-full"
          style={{ opacity: roleCode == 'ACCADMIN' && serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5 || serviceObj?.billingStatus == 6 || props.tab == 1 ? 0.5 : 1 }}
          disabled={roleCode == 'ACCADMIN' && serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5 || serviceObj?.billingStatus == 6 || props.tab == 1}
          onClick={() => { saveService('B') }}>
          {props?.mode == 'Edit' && (roleCode == 'PRADMIN' || roleCode == 'GROUPPRADMIN') ? 'Get Finance Approval' : props?.mode == 'Edit' && roleCode == 'ACCADMIN' ? 'Billing Completed' : 'Get Admin Approval '}
        </button>
        {/* <button className=" h-9  bg-[#004172] text-white rounded-md w-full"
          onClick={() => { saveService('B') }}>
Billing Rejected        </button> */}
        <div>

          {/* <button className="h-9 border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md  w-full"
            style={{ opacity: roleCode == 'ACCADMIN' && serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5 || props.tab == 1 ? 0.5 : 1 }}
            disabled={roleCode == 'ACCADMIN' && serviceObj?.billingStatus == 3 || serviceObj?.billingStatus == 4 || serviceObj?.billingStatus == 5 || props.tab == 1}
            onClick={handleCancel}>
            Cancel
          </button> */}
          <button className="h-9 border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md  w-full"
            onClick={handleCancel}>
            Cancel
          </button>

        </div>
      </div>

    </>
  )

})

export default AddService;