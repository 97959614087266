import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Modal,
  Button,
  Box,
  Pagination,
  TextField,
} from "@mui/material";
import OurLayouts from "../Layouts";
import apiService from "../../services/apiservice";
import { FaEye, FaLaptopHouse, FaSearch } from "react-icons/fa";
import { Sidebar } from "primereact/sidebar";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import AddService from "./addService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { FaFilter } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import PatientSearch from "../Shared/patientSearch";
import { Dropdown } from "primereact/dropdown";
import { TiExport } from "react-icons/ti";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { useGlobalContext } from "../../GlobalContext";
import Divider from "@mui/material/Divider";

const ITEMS_PER_PAGE = 10;
const serviceList = [
  { id: 8, value: "IPD" },
  { id: 9, value: "OPD" },
  { id: 10, value: "Investigation" },
  { id: 11, value: "Day Care " },
];
const billingStsList = [
  {
    value: 1,
    name: "Billing Uploaded",
  },
  {
    value: 3,
    name: "Billing Completed",
  },
  {
    value: 2,
    name: "Billing Done",
  },
  {
    value: 4,
    name: "Billing Approved",
  },
  {
    value: 5,
    name: "Billing Rejected",
  },
  {
    value: 6,
    name: "Billing Rejected by Finance",
  },
];
const HospitalizatioList = [
  { id: 1, value: "General Surgery" },
  { id: 2, value: "Oncology" },
  { id: 3, value: "Neurology" },
  { id: 4, value: "Orthopedic" },
  { id: 5, value: "Urology" },
  { id: 6, value: "Cardiology" },
  { id: 7, value: "ENT" },
  { id: 8, value: "Gynecology" },
  { id: 10, value: "Opthalmology" },
  { id: 11, value: "General Medicine" },
  { id: 15, value: "Nephrology" },
];
const HospitalizatioList1 = [{ id: 12, value: "OPD Others" }];
const HospitalizatioList2 = [{ id: 13, value: "Investigation Others" }];
const HospitalizatioList3 = [{ id: 14, value: "Day Care Others" }];
const serviceTypeDs = [
  { id: 8, value: "IPD" },
  { id: 9, value: "OPD" },
  { id: 10, value: "Investigation" },
  { id: 11, value: "Day Care " },
];
const ServicesList = () => {
  const [loading, setloading] = useState(false);
  const childRef = useRef(null);
  const [child, setchild] = useState(false);
  const [reject, setReject] = useState(false);

  const [addService, setaddService] = useState(false);
  const [inline, setInline] = useState(false);

  const [selectedRow, setSelected] = useState();
  const { exporting, setExporting } = useGlobalContext();

  const [list, setPaginated] = useState([]);
  const [page1, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [viewEnquiry, setViewEnquiry] = useState(false);
  const [patientSearch, setpatientSearch] = useState(false);
  const [patient, setPatient] = useState({});
  const [hospitalList, sethospitalList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  let page = 1;

  const userObj = JSON.parse(localStorage.getItem("loginObj"));
  const roleCode = localStorage.getItem("userRole");
  console.log(roleCode);
  let location;
  if (roleCode === "PRADMIN") {
    location = JSON.parse(localStorage.getItem("location"));
  }
  const [serviceList, setServiceList] = useState([]);
  const formatDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };
  const [filterObj, setfilterObj] = useState({
    fromDate: formatDate(new Date()),
    toDate: formatDate(new Date()),
  });
  const toggleSidebar = () => {
    setaddService(!addService);
  };
  const toggleSidebar1 = () => {
    setloading(false);
    if(inline != true){
      getServicesBillList();}
      else{
        let data = serviceList.filter(item => item.id !== selectedRow.id);
        setServiceList(data)
        setInline(false)
        setloading(false)

      }
  };
  const toggleSidebarFilter = () => {
    setpatientSearch(!patientSearch);
  };
  const handleSelectedData = (e) => {
    let filter = {};
    filter = filterObj || {};
    filter.patientId = e.code;
    filter.patientName = e.name;
    setfilterObj(filter);
    setPatient(e);
  };
  const user = userObj.user;

  const convertUTCToLocal = (date, format) => {
    format = format || "MM/DD/YYYY"; // YYYY-MM-DD hh:mm:ss
    const localTime = moment.utc(date).toDate();
    return moment(localTime).format(format);
  };
  const parseDate = (dateString) => {
    if (!dateString) return;
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "fromDate" || name === "toDate") {
      // Format the date if the input name is fromDate or toDate
      setfilterObj({
        ...filterObj,
        [name]: formatDate(value),
      });
    } else {
      // Handle other inputs
      setfilterObj({
        ...filterObj,
        [name]: value,
      });
    }
  };
  
  const search = () => {
    page = 1;
    setPage(1);
    if(inline != true){
      getServicesBillList();}
      else{
        let data = serviceList.filter(item => item.id !== selectedRow.id);
        setServiceList(data)
        setInline(false)
        setloading(false)
      }
  };
  const campLeadOptions = [
    { id: 1, label: "Yes" },
    { id: 0, label: "No" },
  ];
  
  const getServicesBillList = async () => {
    console.log('Filter Object:', filterObj); // Debugging line
    setServiceList([]);
    let payload = {
      serviceStatus: 1,
      fromDate: filterObj.fromDate
        ? convertUTCToLocal(new Date(filterObj.fromDate))
        : convertUTCToLocal(new Date()),
      toDate: filterObj.toDate
        ? convertUTCToLocal(new Date(filterObj.toDate))
        : convertUTCToLocal(new Date()),
      roleCode: roleCode,
    };
    if (filterObj?.campLead) {
      payload.campLead = filterObj.campLead;
    }
    if (filterObj.billingStatus) {
      payload.billingStatus = filterObj.billingStatus;
    }
    if (activeTab === 1) {
      payload.allBillingFlag = 1;
    }
    if (roleCode == "PRADMIN") {
      payload.locationId = location.locationId;
    }
  
    payload.pageNumber = page;

    let mergedPayload = { ...payload, ...filterObj };
    mergedPayload.fromDate = filterObj.fromDate
      ? convertUTCToLocal(new Date(filterObj.fromDate))
      : convertUTCToLocal(new Date());
    mergedPayload.toDate = filterObj.toDate
      ? convertUTCToLocal(new Date(filterObj.toDate))
      : convertUTCToLocal(new Date());
    if (mergedPayload.billingStatus == null) {
      delete mergedPayload.billingStatus;
    }
    if (mergedPayload.patientId) {
      delete mergedPayload.patientName;
    }
    const newPayload = removeNullValues(mergedPayload);
    setloading(true);
    try {
      const res = await apiService.getServicesBillList(newPayload);
      if (!res.message) {
        let res1 = [];
        res1 = res.data;
        res1.forEach((element) => {
          element.mciPercentage = (
            ((element?.mci || 0) / (element?.gross || 0)) *100).toFixed(2);
          element.gmvPercentage = (
            (element?.curebayFee / element?.gross) *
            100
          ).toFixed(2);
          element.netSalesPercentage = (
            ((element?.yieldAmount || 0) / (element?.curebayFee || 0)) *
            100
          ).toFixed(2);
          element.doctorSharePercentage = (
            ((element?.doctorShare || 0) / (element?.curebayFee || 0)) *
            100
          ).toFixed(2);
        });
  
        setServiceList(res1);
        setloading(false);
        setInline(false)
      }
    } catch (error) {
      console.error("Error fetching enquiry list:", error);
      setloading(false);
      setViewEnquiry(false);
    }
  };
  
  
  const removeNullValues = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== null)
    );
  };
  const handlePageChange = (event, value) => {
    page = value;
    setPage(value);
    if(inline != true){
      getServicesBillList();}
      else{
        let data = serviceList.filter(item => item.id !== selectedRow.id);
        setServiceList(data)
        setInline(false)
        setloading(false)
      }
  };
  const handleClick = (e) => {
    setSelected(e);
    setaddService(true);
  };
  const handleClickreject = (data) => {
    setSelected({...data});
    setReject(true);
  };
  useEffect(() => {
    if(inline != true){
      getHospitalList();

      getServicesBillList();}
      else{
        let data = serviceList.filter(item => item.id !== selectedRow.id);
        setServiceList(data)
        setInline(false)
        setloading(false)
      }
  }, []);
  const rejectTionUpdate = (e, data) => {
    const updatedData = serviceList.map((item) => {
      if (item.id === data.id) {
        // Return a new object with updated key/value
        let x = { ...item, ["reasonForReject"]: e.target.value };
        setSelected(x);
        return x;
      }
      return item; // Return unchanged if not the one to update
    });
    setServiceList(updatedData);
  };
  const handleApproveClick = (e, x) => {
    setInline(true)
    setSelected(e);
    setloading(true);

    if (x == "R" && (!e?.reasonForReject || e?.reasonForReject == null)  ) {
      setReject(true);
      const updatedData = serviceList.map((item) => {
        if (item.id === e.id) {
          // Return a new object with updated key/value
          return { ...item, ["reject"]: true };
        }
        return item; // Return unchanged if not the one to update
      });

      setServiceList(updatedData);
      setloading(false);

      return toast.error("Please enter reason for rejection.");
    }
    if (x == "A") {
      const updatedData = serviceList.map((item) => {
        if (item.id === e.id) {
          // Return a new object with updated key/value
          return { ...item, ["reject"]: false };
        }
        return item; // Return unchanged if not the one to update
      });

      setServiceList(updatedData);
    }
    let object = {};
    object = e || {};
    object.reject = x === "R" ? true : false;
    object.reasonForReject = x === "R" ? object.reasonForReject : null;

    setSelected(e);

    setTimeout(() => {
      setReject(false);

      if (childRef.current) {
        childRef.current.fromParent(e);
      }
    }, 2000);
  };
  const getbillingStyle = (e) => {
    let payload = {
      color:
        e == 1
          ? "#ECAC09"
          : e == 2
          ? "violet"
          : e == 3
          ? "#7225EF"
          : e == 4
          ? "#299F5D"
          : e == 5
          ? "#DD0000"
          : e == 6
          ? "#DD0000"
          : "#3D3BA4",
    };
    return payload;
  };
  const getHeader = () => {
    return (
      <div className="font-poppins  text-primary text-xl font-bold leading-6 tracking-normal text-left">
        {"Edit Service"}
      </div>
    );
  };
  // useEffect(() => {
  //   setPaginated([]);
  //   const startIndex = (page - 1) * ITEMS_PER_PAGE;
  //   const endIndex = startIndex + ITEMS_PER_PAGE;
  //   const filteredServiceList = serviceList.filter((item, index) => index >= startIndex && index < endIndex);
  //   setPaginated(filteredServiceList);
  // }, [serviceList, page]);
  const handleClickFilter = (data, mode) => {
    setViewEnquiry(true);
  };
  const getHospitalList = async () => {
    let payload = {
      status: 1,
      HospitalType: "H",
    };
    let res = await apiService.getHospitalList(payload);
    console.log(res);
    res.data.forEach(element => {
      element["hospitalLocationNames"] =
      element.hospitalName + " (" + element.name + ")";
  
    });
    sethospitalList(res.data);
  };
  const handleChangeFilter = (e) => {
    if (e.target?.id != "date") {
      if (
        e.target.name == "serviceType" ||
        e.target.name == "hospitalizationTypeId"
      ) {
        setSubTypeList(null);
      }
      setfilterObj({
        ...filterObj,
        [e.target.name]: e.target.value,
      });
    } else {
      setfilterObj({
        ...filterObj,
        [e.target.name]: formatDate(e.target.value),
      });
    }
  };
  useEffect(() => {
    if (filterObj?.hospitalizationTypeId && filterObj?.serviceType) {
      getSubList();
    }
    if (filterObj?.serviceType) {
      setfilterObj({
        ...filterObj,
        ["hospitalizationTypeId"]: null,
        ["procedureId"]: null,
      });
    }
    setSubTypeList(null);
  }, [filterObj?.serviceType]);
  useEffect(() => {
    if (filterObj?.hospitalizationTypeId && filterObj?.serviceType) {
      getSubList();
    }
  }, [filterObj?.hospitalizationTypeId]);
  const [subtypedropdownList, setSubTypeList] = useState(null);
  const getSubList = async () => {
    let payload = {
      serviceType: filterObj.serviceType,
      hosId: filterObj.hospitalizationTypeId,
    };
    let res = await apiService.getHosSubType(payload);
    if (res.data?.data?.length) {
      setSubTypeList(res.data?.data);
    }
  };
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if(inline != true){
    getServicesBillList();}
    else{
      let data = serviceList.filter(item => item.id !== selectedRow.id);
      setServiceList(data)
      setInline(false)
      setloading(false)
  
    }
  }, [activeTab, , addService]);
  const handleCancel = () => {
    setfilterObj({});
  };
  const filterService = async () => {
    setServiceList([]);
    let payload2 = {
      serviceStatus: 1,
      fromDate: filterObj.fromDate
        ? convertUTCToLocal(new Date(filterObj.fromDate))
        : convertUTCToLocal(new Date()),
      toDate: filterObj.toDate
        ? convertUTCToLocal(new Date(filterObj.toDate))
        : convertUTCToLocal(new Date()),
      roleCode: roleCode,
    };
    if (activeTab === 1) {
      payload2.allBillingFlag = 1;
    }
    if (roleCode == "PRADMIN") {
      payload2.locationId = location.locationId;
      // payload.hospitalId=location.hospitalId
    }
    payload2.pageNumber = page;
    let mergedPayload = { ...payload2, ...filterObj };
    mergedPayload.fromDate = filterObj.fromDate
      ? convertUTCToLocal(new Date(filterObj.fromDate))
      : convertUTCToLocal(new Date());
    mergedPayload.toDate = filterObj.toDate
      ? convertUTCToLocal(new Date(filterObj.toDate))
      : convertUTCToLocal(new Date());
    if (mergedPayload.billingStatus == null) {
      delete mergedPayload.billingStatus;
      // delete mergedPayload.roleCode
      // delete mergedPayload.fromDate
      // delete mergedPayload.toDate
    }
    if (activeTab === 0) {
      delete mergedPayload.serviceStatus;
    }
    setloading(true);
    try {
      const res = await apiService.getServicesBillList(mergedPayload);
      if (!res.message) {
        let res1 = [];
        res1 = res.data;
        res1.forEach((element) => {
          element.mciPercentage = (
            ((element?.mci || 0) / (element?.gross || 0)) *100).toFixed(2);
          element.gmvPercentage = (
            (element?.curebayFee / element?.gross) *
            100
          ).toFixed(2);
          element.netSalesPercentage = (
            ((element?.yieldAmount || 0) / (element?.curebayFee?.toFixed(2) || 0)) *
            100
          ).toFixed(2);
          element.doctorSharePercentage = (
            ((element?.doctorShare || 0) / (element?.curebayFee || 0)) *
            100
          ).toFixed(2);
        });
        setServiceList(res1);
        setloading(false);
        setViewEnquiry(false);
      }
    } catch (error) {
      console.error("Error fetching enquiry list:", error);
      setloading(false);
      setViewEnquiry(false);
    }
  };
  const exportToexcel = async () => {
    setExporting(true);
    let exporrtExcel = [];

    let excel = [];
    let payload2 = {
      serviceStatus: 1,
      fromDate: filterObj.fromDate
        ? convertUTCToLocal(new Date(filterObj.fromDate))
        : convertUTCToLocal(new Date()),
      toDate: filterObj.toDate
        ? convertUTCToLocal(new Date(filterObj.toDate))
        : convertUTCToLocal(new Date()),
      roleCode: roleCode,
    };
    if (activeTab === 1) {
      payload2.allBillingFlag = 1;
    }
    if (roleCode == "PRADMIN") {
      payload2.locationId = location.locationId;
      // payload.hospitalId=location.hospitalId
    }
    let mergedPayload = { ...payload2, ...filterObj };
    mergedPayload.fromDate = filterObj.fromDate
      ? convertUTCToLocal(new Date(filterObj.fromDate))
      : convertUTCToLocal(new Date());
    mergedPayload.toDate = filterObj.toDate
      ? convertUTCToLocal(new Date(filterObj.toDate))
      : convertUTCToLocal(new Date());
    if (mergedPayload.billingStatus == null) {
      delete mergedPayload.billingStatus;
    }
    if (activeTab === 0) {
      delete mergedPayload.serviceStatus;
    }
    let res = {
      data: [],
    };
    let needPages = serviceList[0].pagination.totalRecords / 50;
    // mergedPayload.pageSize = 50;
    // for (let index = 0; index < Math.ceil(needPages); index++) {
    //   mergedPayload.pageNumber = index + 1;
      const res1 = await apiService.exportServiceList(mergedPayload);
      res.data.push(...res1.data);
    // }

    if (res.data.length == 0) {
      setExporting(false);

      return toast.error("No Data Found");
    }  
    let headers = [
      "EnquiryId",
      "PatientId",
      "PatientName",
      "Conceirge Name",
      "Email",
      "Mobile",
      // "Enquiry Date",
      "Eclinic Name",
      "Eclinic Location",
      "Hospital",
      "Gross",
      "MCI",
      "Amount",
      "Hospitalization Sub Type",
      "Enquiry Date",
      " Billing  Date",
      "From Date",
      "To Date",
      "Billing Status",
      "Reason For Reject",
      "Camp Lead",

    ];

    exporrtExcel.push(headers);
    for (let i = 0; i < res.data.length; i++) {
      excel = [
        res.data[i].serviceEnquiryId,
        res.data[i].patientId,
        res.data[i].patientName,
        res.data[i].conciergeName,
        res.data[i]?.patientEmail || "",
        res.data[i].patientMobileNo,
        // res.data[i].createdDate,
        res.data[i].cheCenterName,
        res.data[i].cheBranchName,
        res.data[i].hospitalName,
        res.data[i].gross,
        res.data[i].mci,
        res.data[i].totalAmount,
        res.data[i].procedureName,
      moment(res.data[i].enquiryDate).format("DD-MM-YYYY"),
        moment(res.data[i].procedureDate).format("DD-MM-YYYY"),
        moment(res.data[i].admissionFromDate).format("DD-MM-YYYY"),
        moment(res.data[i].admissionToDate).format("DD-MM-YYYY"),
        res.data[i].billingStatus == 1
          ? "Billing Uploaded"
          : res.data[i].billingStatus == 3
          ? "Billing Completed"
          : res.data[i].billingStatus == 2
          ? "Billing Done"
          : res.data[i].billingStatus == 4
          ? "Billing Approved"
          : res.data[i].billingStatus == 5
          ? "Billing Rejected"
          : "",
          res.data[i].reasonForReject || "",
          res.data[i]. campLead == 0 ? 'NO': res.data[i]. campLead == 1? 'YES':'',

      ];
      exporrtExcel.push(excel);
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(exporrtExcel);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "exported_data.xlsx");
    setExporting(false);
  };
  const [billingView, setBillingView] = useState(false);
  const [billingData, setBillingData] = useState();
  const onViewPastBilling = async (data) => {
    let payload = {
      patientId: data?.patientId,
      pageNumber: 1,
    };
    const res = await apiService.getServicesBillListByPatient(payload);
    if (!res.data || res.data.length == 0) {
      return toast("No data found");
    } else {
      setBillingData(res.data);
      if (data?.id) {
        setBillingView(true);
      }
    }
  };
  const handlePageChangebilling = async (event, value) => {
    setBillingData();
    setloading(true);
    setPage2(value);
    let payload = {
      patientId: billingData[0].patientId,
      pageNumber: value,
    };
    const res = await apiService.getServicesBillListByPatient(payload);
    if (!res.data || res.data.length == 0) {
      setloading(false);
      return toast("No data found");
    } else {
      setBillingData(res.data);
      setloading(false);
    }
  };
  const onHideBilling = () => {
    setBillingView(false);
    setPage2(1);
  };
  return (
    <>
      <OurLayouts>
        <Backdrop
          sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
        <div>
          <Button icon="pi pi-bars" onClick={toggleSidebar} />
          <Sidebar
            style={{ width: "60%", borderBottom: "1px solid #ccc" }}
            dismissable={false}
            visible={viewEnquiry}
            header={"Filter Service"}
            centered={true}
            onHide={() => setViewEnquiry(false)}
            position="right"
          >
            <div className="grid mt-4  grid-cols-2 gap-4 ">
              <div>
                <label htmlFor="dob">From Date</label>
                <Calendar
                  id="date"
                  className="border border-gray-300 rounded-md p-2 w-full"
                  dateFormat="dd/mm/yy"
                  style={{ height: "40px" }}
                  onChange={(e) => handleChangeFilter(e)}
                  name="fromDate"
                  value={parseDate(filterObj?.fromDate)}
                  placeholder="Select From Date"
                />
              </div>
              <div>
                <label htmlFor="dob">To Date </label>
                <Calendar
                  id="date"
                  className="border border-gray-300 rounded-md p-2 w-full"
                  dateFormat="dd/mm/yy"
                  style={{ height: "40px" }}
                  onChange={(e) => handleChangeFilter(e)}
                  name="toDate"
                  value={parseDate(filterObj?.toDate)}
                  placeholder="Select To Date"
                />
              </div>
              <div>
                <label htmlFor="emailId">Patient Name</label>
                <div className="relative">
                  <InputText
                    id="patientName"
                    className="border border-gray-300 rounded-md p-2 pr-10 w-full"
                    value={filterObj?.patientName ? filterObj?.patientName : ""}
                    name="patientName"
                    placeholder="Search for Patient Name"
                    readOnly
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                    onClick={toggleSidebarFilter}
                  >
                    <svg
                      className="w-5 h-5 text-gray-400"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M21 21l-5.2-5.2"></path>
                      <circle cx="10" cy="10" r="7"></circle>
                    </svg>
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="name">Preferred Hospitals</label>
                <Dropdown
                  optionLabel="hospitalLocationNames" // This tells Dropdown how to display the options
                  placeholder="Select Preferred Hospitals"
                  options={hospitalList}
                  name="hospitalId"
                  value={filterObj?.hospitalId}
                  optionValue="hospitalCode"
                  style={{ height: "45px" }}
                  className="border border-gray-300 rounded-md p-1 w-full"
                  onChange={(e) => handleChangeFilter(e)}
                  filter
                />
              </div>
              <div>
                <label htmlFor="serviceEnquiryId">Enquiry Id</label>
                <div className="relative">
                  <InputText
                    id="serviceEnquiryId"
                    name="serviceEnquiryId"
                    className="border border-gray-300 rounded-md p-2 pr-10 w-full"
                    onChange={(e) => handleChangeFilter(e)}
                    value={
                      filterObj?.serviceEnquiryId
                        ? filterObj?.serviceEnquiryId
                        : ""
                    }
                    type="number"
              pattern="\d*"
                    placeholder="Search for Enquiry Id"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="name">
                  Service Type<span className="text-red-500">*</span>
                </label>
                <Dropdown
                  value={filterObj?.serviceType ? filterObj?.serviceType : ""}
                  options={serviceTypeDs}
                  optionLabel="value" // This tells Dropdown how to display the options
                  placeholder="Select a Procedure"
                  optionValue="id"
                  name="serviceType"
                  className="border border-gray-300 rounded-md  w-full"
                  onChange={(e) => handleChangeFilter(e)}
                  filter
                />
              </div>
              <div>
                <label htmlFor="name">Hospitalization Type</label>
                <Dropdown
                  optionLabel="value" // This tells Dropdown how to display the options
                  placeholder="Select Hospitalization Type"
                  options={
                    filterObj?.serviceType == 8
                      ? HospitalizatioList
                      : filterObj?.serviceType == 9
                      ? HospitalizatioList1
                      : filterObj?.serviceType == 10
                      ? HospitalizatioList2
                      : filterObj?.serviceType == 11
                      ? HospitalizatioList3
                      : HospitalizatioList
                  }
                  name="hospitalizationTypeId"
                  value={
                    filterObj?.hospitalizationTypeId
                      ? filterObj?.hospitalizationTypeId
                      : ""
                  }
                  optionValue="id"
                  style={{ height: "45px" }}
                  className="border border-gray-300 rounded-md p-1 w-full"
                  onChange={(e) => handleChangeFilter(e)}
                  filter
                />
              </div>
              {filterObj?.hospitalizationTypeId && (
                <div>
                  <label htmlFor="name">Hospitalization Subtype</label>
                  <Dropdown
                    optionLabel="hospitalizationSubType" // This tells Dropdown how to display the options
                    placeholder="Select Hospitalization Subtype"
                    options={subtypedropdownList ? subtypedropdownList : ""}
                    name="procedureId"
                    value={filterObj?.procedureId ? filterObj?.procedureId : ""}
                    optionValue="hospitalizationSubTypeId"
                    style={{ height: "45px" }}
                    className="border border-gray-300 rounded-md p-1 w-full"
                    onChange={(e) => handleChangeFilter(e)}
                    filter
                  />
                </div>
              )}
              <div>
                <label htmlFor="name">CAMP LEAD</label>
                <Dropdown
  optionLabel="label"
  placeholder="Select Camp lead or Not"
  options={campLeadOptions}
  value={filterObj?.campLead}
  optionValue="id"
  className="border border-gray-300 rounded-md w-full"
  onChange={(e) => handleChange(e)}
  name="campLead"
  filter
/>

              </div>
              {activeTab != 0 && (
                <>
                <div>
                  <label htmlFor="name">
                    Billing Status<span className="text-red-500">*</span>
                  </label>
                  <Dropdown
                    value={filterObj?.billingStatus ? filterObj?.billingStatus : ""}
                    options={billingStsList}
                    optionLabel="name" // This tells Dropdown how to display the options
                    placeholder="Select Billing Status"
                    optionValue="value"
                    name="billingStatus"
                    className="border border-gray-300 rounded-md  w-full"
                    onChange={(e) => handleChangeFilter(e)}
                    filter />
                </div>
                <div>
                    <label htmlFor="name">CAMP LEAD</label>
                    <Dropdown
                      optionLabel="label"
                      placeholder="Select Camp lead or Not"
                      options={campLeadOptions}
                      value={filterObj?.campLead}
                      optionValue="id"
                      className="border border-gray-300 rounded-md w-full"
                      onChange={(e) => handleChange(e)}
                      name="campLead"
                      filter />

                  </div>
                  </>
              )}
            </div>
            <div className="grid mt-36 ml-12 grid-cols-2 gap-4">
              <div>
                <button
                  className=" h-9  bg-[#004172] text-white rounded-md w-full"
                  onClick={getServicesBillList}
                >
                  Ok
                </button>
              </div>
              <div>
                <button
                  className=" h-9 border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md   w-full"
                  onClick={handleCancel}
                >
                  Clear
                </button>
              </div>
            </div>
          </Sidebar>
          <Sidebar
            style={{ width: "60%", borderBottom: "1px solid #ccc" }}
            dismissable={false}
            visible={addService}
            header={getHeader}
            centered={true}
            onHide={() => toggleSidebar(false)}
            position="right"
          >
            {/* Header and Hide button on the same row */}
            {/* <div style={{ display: 'flex', alignItems: 'center' ,height:'1px', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
              <div className="font-poppins text-base text-primary text-3xl font-bold leading-6 tracking-normal text-left">{mode === 'View' ? 'View Service Enquiry Details' : mode === 'Assign' ? 'Assign to Me' : mode === 'Filter' ? 'Filter Enqiury' : 'Add Service'}</div>
              <Button icon="pi pi-times" onClick={() => setViewEnquiry(false)} className="p-button-text p-button-danger p-button-sm" />
            </div> */}

            {/* Content for your sidebar */}

            <div>
              <AddService
                selected={selectedRow}
                tab={activeTab}
                // ref={childRef}
                mode="Edit"
                onClose={toggleSidebar}
              />
            </div>
          </Sidebar>
        </div>
        <div className="flex flex-col w-full mt-[7rem] overflow-auto ">
          <div className="flex justify-between mt-[3rem] ">
            <div>
              <h1 className="text-2xl  text-primary  font-semibold ">
                Service List
              </h1>
            </div>
            <div className="flex  border h-10 rounded-md pt-1 pb-1 mr-12 justify-items-center">
              <div className="flex  mx-2 border-r-2">
                <Button
                  className="flex gap-1 "
                  onClick={(e) => handleClickFilter({}, "Filter")}
                >
                  <FaFilter className="text-primary" />
                  <h6 className="text-primary">Filter</h6>
                </Button>
              </div>
              <div className="flex  mx-1 ">
                <Button
                  className="flex gap-1"
                  onClick={() => exportToexcel()}
                  disabled={exporting == true}
                >
                  <TiExport
                    className="text-2xl"
                    style={{ color: exporting ? "green" : "#004172" }}
                  />

                  <h6 style={{ color: exporting ? "green" : "#004172" }}>
                    {exporting == true ? "Downloading..." : "Export Excel"}
                  </h6>
                </Button>
                {exporting == true && (
                  <ProgressSpinner
                    style={{ width: "20px", height: "20px" }}
                    strokeWidth="8"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center mt-4 gap-2 mr-10">
            {activeTab != 0 && (
              <div>
                <Dropdown
                  value={
                    filterObj?.billingStatus ? filterObj?.billingStatus : ""
                  }
                  options={billingStsList}
                  optionLabel="name" // This tells Dropdown how to display the options
                  placeholder="Select Billing Status"
                  optionValue="value"
                  name="billingStatus"
                  className="border border-gray-300 rounded-md  w-full"
                  onChange={(e) => handleChangeFilter(e)}
                  filter
                />
              </div>
            )}
            <div>
              <Calendar
                id="fromDate"
                className="border border-gray-300 rounded-md p-2"
                placeholder="Select FromDate"
                onChange={(e) => handleChange(e)}
                value={parseDate(filterObj?.fromDate)}
                name="fromDate"
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
              />
            </div>
            <div>
              <Calendar
                id="toDate"
                className="border border-gray-300 rounded-md p-2"
                name="toDate"
                placeholder="Select ToDate"
                onChange={(e) => handleChange(e)}
                value={parseDate(filterObj?.toDate)}
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                minDate={parseDate(filterObj.fromDate)} // Set maxDate based on fromDate
              />
            </div>
            <button
              className="w-32 h-9 bg-[#004172] text-white rounded-full"
              onClick={search}
            >
              Search
            </button>
            <button
  className="w-32 h-9 bg-white text-[#004172] rounded-full border border-[#004172]"
  onClick={search}
>
  Refresh
</button>

          </div>

          <div
            className="w-[99%] mt-8 rounded-[5px]"
            style={{ boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.06)" }}
          >
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => handleChangeTab(newValue)}
            >
              <Tab
                label={<span style={{ fontWeight: "bold" }}>My Service</span>}
              />
              <Tab label={<span style={{ fontWeight: "bold" }}>ALL</span>} />
            </Tabs>
            <TableContainer
              component={Paper}
              className="print-table"
              style={{ width: "100%", overflowX: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow >
                    
                  {roleCode == "ACCADMIN" && activeTab == 0 && (
                      <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                        Approval
                      </TableCell>
                    )}
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Enquiry ID
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                     Enquiry Date
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                    Billing  Date
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                     Discharge Date
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                    Date Difference
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Patient ID
                    </TableCell>
                
                 
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Name
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Hospital
                    </TableCell>
                    
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Payment Type
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Gross
                    </TableCell>
                     
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      MCI
                    </TableCell>
                    
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      GMV
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Net Sales
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      MCI %
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      GMV %
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Net Sales %
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      View Bill
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Billing Details
                    </TableCell>
                     
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Refferal Type
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Surgon Share
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Hospitalization SubType
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Billing Status
                    </TableCell>
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Eclinic Name
                    </TableCell>
                
                    <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                     Camp
                    </TableCell>
                    {(roleCode == "GROUPPRADMIN" || roleCode == "ACCADMIN") && (
                      <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                        Reason
                      </TableCell>
                    )}

                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceList.map((notifi) => (
                    <TableRow key={notifi.patientId} style={{backgroundColor : notifi.dischargeDateHighlight == 1 ?'lightgoldenrodyellow': notifi.dischargeDateHighlight == 2 ? 'gold': notifi.dischargeDateHighlight == 3 ? 'red' : '' }}>

                           {roleCode == "ACCADMIN" && activeTab == 0 && (
                        <TableCell
                          style={{ fontSize: "12px", fontWeight: 500 }}
                        >
                          <div className="">
                            <button
                              className=" h-9 w-28 bg-[#004172] text-white rounded-md w-full"
                              onClick={() => handleApproveClick(notifi, "A")}
                            >
                              Approve
                            </button>
                            <button
                              className="mt-1 w-28 h-9 border border-[#004172] bg-[#fff] text-black rounded-md w-full"
                              onClick={() => handleApproveClick(notifi, "R")}
                            >
                              Reject
                            </button>
                          </div>
                        </TableCell>
                      )}
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.serviceEnquiryId}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {" "}
                        {moment(notifi.enquiryDate).format("DD-MM-YYYY")}{" "}
                      </TableCell>
                        
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {" "}
                        {moment(notifi.procedureDate).format("DD-MM-YYYY")}{" "}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {" "}
                        {moment(notifi.admissionToDate).format("DD-MM-YYYY")}{" "}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {" "}
                        {notifi.dischargeDays}{" "}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.patientId}
                      </TableCell>

                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.patientName}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.hospitalName}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.paymentMode}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.gross}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.mci}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.curebayFee}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.yieldAmount}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.mciPercentage}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.gmvPercentage}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                      {notifi?.netSalesPercentage}
                      </TableCell>
                      <TableCell>
                        {" "}
                        <a
                          href={`${
                            process.env.REACT_APP_IMAGE_URL +
                            notifi.uploadBillName
                          }`}
                          target="_blank"
                        >
                          <FaEye />
                        </a>
                      </TableCell>
                      <TableCell style={{ fontSize: "15px", fontWeight: 500 }}>
                        <span onClick={() => onViewPastBilling(notifi)}>
                          <FaEye />
                        </span>
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.referral == 0 ? "Refferral" : "Non-Referral"}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.doctorShare}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {" "}
                        {notifi.procedureName}{" "}
                      </TableCell>
                      <TableCell style={{ fontSize: "15px", fontWeight: 500 }}>
                        <span style={getbillingStyle(notifi.billingStatus)}>
                          {notifi.billingStatus == 1
                            ? "Billing Uploaded"
                            : notifi.billingStatus == 3
                            ? "Billing Completed"
                            : notifi.billingStatus == 2
                            ? "Billing Done"
                            : notifi.billingStatus == 4
                            ? "Billing Approved"
                            : notifi.billingStatus == 5
                            ? "Billing Rejected"
                            : notifi.billingStatus == 6
                            ? "Billing Rejected by Finance"
                            : ""}
                        </span>
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi.cheCenterName}
                      </TableCell>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                      {notifi?.campLead == 0 ? "No" : "Yes"}
                      </TableCell> 
                      {roleCode == "ACCADMIN" && notifi.billingStatus == 6 ? (
                        <TableCell className="">
                          {/* {(notifi.reject == true || notifi?.reasonForReject) && <InputText style={{ width: "10rem" }}  className="border border-gray-300 rounded-md p-2 pr-10 w-full"
                          onChange={(e) => rejectTionUpdate(e, notifi)}
                        placeholder="Enter Reason" />} */}
                          <FaEye onClick={() => handleClickreject(notifi)} />
                        </TableCell>
                      ) : roleCode == "GROUPPRADMIN" ? (
                        <TableCell
                          style={{ fontSize: "13px", fontWeight: 500 }}
                        >
                          {" "}
                          {notifi?.reasonForReject ? (
                            <FaEye onClick={() => handleClickreject(notifi)} />
                          ) : null}{" "}
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                      {/* {roleCode == 'GROUPPRADMIN' && <TableCell style={{ fontSize: '13px', fontWeight: 500 }}> {notifi?.reasonForReject ? <FaEye onClick={() => handleClickreject(notifi)} /> : null} </TableCell>} */}

                      {roleCode != "CE" && (
                        <TableCell>
                          {" "}
                          <button
                            className="w-32 h-9  bg-[#004172] text-white  rounded-full"
                            onClick={() => handleClick(notifi)}
                          >
                            Edit
                          </button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Pagination
                count={Math.ceil(serviceList[0]?.pagination?.totalRecords / 10)}
                page={page1}
                color="secondary"
                onChange={handlePageChange}
              />
            </Box>
          </div>
          <Sidebar
            style={{ width: "80%", borderBottom: "1px solid #ccc" }}
            dismissable={false}
            visible={billingView}
            onHide={() => onHideBilling()}
            position="right"
          >
            <>
              <div>
                {billingData && billingData.length && (
                  <>
                    <div class="w-118 h-10 top-98 left-47  bg-lightBlue bg-opacity-5.5 overflow-auto">
                      <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">
                        Patient Name - {billingData[0].patientName}
                      </Typography>
                    </div>
                    <div class="w-118 h-10 top-98 left-47  bg-lightBlue bg-opacity-5.5 overflow-auto">
                      <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">
                        Total Billing -{" "}
                        {billingData[0]?.pagination?.totalRecords}
                      </Typography>
                    </div>
                  </>
                )}

                <TableContainer
                  component={Paper}
                  style={{ width: "100%", height: "36vw", overflowX: "auto" }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>ID</TableCell> */}
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Enquiry ID
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Enquiry Date
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Billing Date
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Patient ID
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Patient Name
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Procedure Name
                        </TableCell>
                        {/* <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>eClinic Name</TableCell> */}
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Preffered Hospital & Clinic
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          DoctorName
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Gross Amount
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          MCI
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Net Sale
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Total Amount
                        </TableCell>
                      { roleCode == 'ACCADMIN' && <TableCell style={{ fontSize: "15px", fontWeight: 600 }}>
                      Updated By
                    </TableCell>}
                        <TableCell
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Billing Status
                        </TableCell>
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {billingData?.map((notifi) => (
                        <TableRow key={notifi.id}>
                          {/* <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.id}</TableCell> */}
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.serviceEnquiryId}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                          {moment(notifi.enquiryDate).format("DD-MM-YYYY")}{" "}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                          {moment(notifi.procedureDate).format("DD-MM-YYYY")}{" "}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.patientId}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.patientName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.procedureName}
                          </TableCell>
                          {/* <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.cheCenterName}</TableCell> */}
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.hospitalName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.referralUserName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.gross}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.mci}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.yieldAmount.toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            {notifi.totalAmount}
                          </TableCell>
                          { roleCode == 'ACCADMIN' && <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {" "}
                        {notifi.approvedBy}{" "}
                      </TableCell>}
                          <TableCell
                            style={{ fontSize: "15px", fontWeight: 500 }}
                          >
                            <span style={getbillingStyle(notifi.billingStatus)}>
                              {notifi.billingStatus == 1
                                ? "Billing Uploaded"
                                : notifi.billingStatus == 3
                                ? "Billing Completed"
                                : notifi.billingStatus == 2
                                ? "Billing Done"
                                : notifi.billingStatus == 4
                                ? "Billing Approved"
                                : notifi.billingStatus == 5
                                ? "Billing Rejected"
                                : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Pagination
                    count={
                      billingData
                        ? Math.ceil(
                            billingData[0]?.pagination?.totalRecords /
                              ITEMS_PER_PAGE
                          )
                        : ""
                    }
                    page={page2}
                    color="secondary"
                    onChange={handlePageChangebilling}
                  />
                </Box>
                <Divider />
                <div className="bg-white"></div>
              </div>
            </>
          </Sidebar>
        </div>
      </OurLayouts>
      <PatientSearch
        showbar={patientSearch}
        setshowBar={toggleSidebarFilter}
        onSelect={handleSelectedData}
      />
      <div className="hidden">
        <AddService
          ref={childRef}
          selected={selectedRow ? selectedRow : {}}
          mode="Edit"
          tab={activeTab}
          onCancel={toggleSidebar1}
          mode2='fromList' 
        />
      </div>
      <Sidebar
        header={"Reason For Rejection"}
        style={{ width: "30%", borderBottom: "1px solid #ccc" }}
        dismissable={false}
        visible={reject == true}
        onHide={() => setReject(false)}
        position="right"
      >
        <div className="flex grid grid-cols-1">
          <TextField
            multiline
            placeholder="Enter the Reason"
            disabled={
              selectedRow?.billingStatus == 6 || selectedRow?.billingStatus == 5
            }
            id="reasonForReject"
            name="reasonForReject"
            value={
              selectedRow?.reasonForReject ? selectedRow?.reasonForReject : ""
            }
            onChange={(e) => rejectTionUpdate(e, selectedRow)}
          />
        </div>
        {/* <div className='flex grid grid-cols-1'>
<TextField  multiline placeholder='Enter the Reason'/>
</div> */}
        {selectedRow?.billingStatus != 6 && selectedRow?.billingStatus != 5 && (
          <button
            className="w-32 h-9 mt-10  bg-[#004172] text-white  rounded-full"
            onClick={() => handleApproveClick(selectedRow, "R")}
          >
            Reject
          </button>
        )}
      </Sidebar>
    </>
  );
};

export default ServicesList;
